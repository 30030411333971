import React from "react";
import LandingFaqItem from "./LandingFaqItem";

class LandingFaq extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  render() {
    const { cms_section_faqs, overnight, tile_view, sort_index_gid, question_gid, answer_gid } = this.props;
    if (cms_section_faqs === null || cms_section_faqs === undefined) {
      return <></>;
    }

    let renderedQuestions = cms_section_faqs["web_pages"].sort((a,b) => a.data[sort_index_gid] - b.data[sort_index_gid]).map((item, index) =>
      <LandingFaqItem item={item} key={`faq-item-${index}`} overnight={overnight} tile_view={tile_view} question_gid={question_gid} answer_gid={answer_gid}/>
    )
    return renderedQuestions;
  }
}

export default LandingFaq;
