import React, { useState } from "react";
const LandingPageHeaderNavMobile = ({header_sections, include_images, is_japanese}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isJapanese, setIsJapanese] = useState(is_japanese);

  const toggleJapanese = () => {
    let el_jp = document.getElementsByClassName("japanese");
    let el_en = document.getElementsByClassName("english");
    for (let el of el_jp) {
      el.classList.remove('landing-hidden');
    }
    for (let el of el_en) {
      el.classList.add('landing-hidden');
    }
    setIsOpen(false);
    setIsJapanese(true);
  };
  const toggleEnglish = () => {
    let el_jp = document.getElementsByClassName("japanese");
    let el_en = document.getElementsByClassName("english");
    for (let el of el_jp) {
      el.classList.add('landing-hidden');
    }
    for (let el of el_en) {
      el.classList.remove('landing-hidden');
    }
    setIsOpen(false);
    setIsJapanese(false);
  };

  const renderMenu = () => {
    return (
      <div className="landing-page-header-mobile-menu">
        {isOpen ? (
          renderMenuInner()
        ):""}
      </div>
    )
  }

  const renderMenuInner = () => {
    const renderedMenuInner = header_sections.map(item =>
      <a href={`#${item.id}`}>
        <div class="landing-page-header-nav-item" onClick={() => setIsOpen(!isOpen)} key={`header-item-mobile-${item.id}`}>
          {
            include_images ?
            <img src={`/images/landing/ico-${item.id}.svg`} />
            :
            <></>
          }
          {
            isJapanese ?
            <div className="body1">{item.title_japanese}</div>
            :
            <div className="body1">{item.mobile_title}</div>
          }
        </div>
      </a>

    );
    renderedMenuInner.push(
      <>
      {
        isJapanese ?
        <a onClick={toggleEnglish} className="japanese" style={{cursor: 'pointer'}}>
          <div className="landing-page-header-nav-item">
            <div className="body1">English</div>
          </div>
        </a>
        :
        <a onClick={toggleJapanese} className="english" style={{cursor: 'pointer'}}>
          <div className="landing-page-header-nav-item">
            <div className="body1">日本語</div>
          </div>
        </a>
      }
      </>
    );
    return (
      <div class="landing-page-header-nav-wrapper">
        {renderedMenuInner}
      </div>
    );
  }

  const getOpenImage = () => {
    return 'ico-menuopen.svg';
  }
  const getCloseImage = () => {
    return 'ico-menuclose.svg';
  }

  const renderDropdownIcon = () => {
    return (
      <img
        onClick={() => setIsOpen(!isOpen)}
        src={`/images/landing/${isOpen ? getCloseImage() : getOpenImage()}`}
        className={`sg-landing-page-mobile-menu-dropdown-icon ${isOpen ? "open" : "closed"}`}
        alt=""
      />
    )
  }

  return (
    <>
      <div className="navbar-mobile-landing-page-addition">
        <div className="container sg-header-container"></div>
        {renderDropdownIcon()}
        {renderMenu()}
      </div>
    </>
  );
};

export default LandingPageHeaderNavMobile;
