import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      showDecline: false,
      showRegistered: false,
      showNotFound: false,
      showRsvpPassed: false
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verifyEmail(e) {
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, selectedDate} = this.state;
    if(features &&  features.pick_a_date) {
      if(selectedDate == null) {
        this.setState({
          error: "Please select a program to register for."
        })
        return;
      }
    }
    fetchAPI(`/registration/verify_email`,(json)=>{
      let error = null;
      let attendee = null;
      let declined = null;
      let registered = null;
      let passedRSVP = null;
      let notFound = null;
      if(json){
        attendee = json.attendee;
        error = json.error;
        declined = json.declined;
        registered = json.registered;
        passedRSVP = json.passed_rsvp;
        notFound = json.not_found;
      }else {

      }

      if( error ) {
        console.log({error})
        if (declined) {
          this.setState({
            showDecline: true,
            showRegistered: false,
            showNotFound: false,
            showRsvpPassed: false,
            error: error
          });
        } else if (registered) {
          this.setState({
            showDecline: false,
            showRegistered: true,
            showNotFound: false,
            showRsvpPassed: false,
            error: error
          });
        } else if (notFound) {
          this.setState({
            showDecline: false,
            showRegistered: false,
            showNotFound: true,
            showRsvpPassed: false,
            error: error
          })
        } else if (passedRSVP) {
          this.setState({
            showDecline: false,
            showRegistered: false,
            showNotFound: false,
            showRsvpPassed: true,
            error: error
          })
        } else {
          this.setState({
            error: error
          });
        }

      } else if (!error && attendee) {
        window.location = `/registration?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { overnight } = this.props;
    const { emailValue, error, showDecline, showRegistered, showNotFound, showRsvpPassed } = this.state;
    return (
      <div className="row" style={{color: "#3D3D3D"}}>
        <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form ">
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: "60px"}}>
              <h5>エグゼクティブ イノベーション フォーラム @ 2025 日本グランプリへのご参加を心よりお待ちしております。</h5>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <h6>本イベントは招待制となりますので、ご登録者のメールアドレスを以下に入力くださいますようお願いいたします。</h6>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: "40px"}}>
              <h5>We look forward to welcoming you to the Executive Innovation Forum at the 2025 Japanese Grand Prix in Suzuka, Japan.</h5>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <h6>As this is an invitation only event, please ensure the email address entered below is&nbsp;that&nbsp;of the registrant.</h6>
            </div>
            <div className="att-proam-registration-email-validation-fields">
              <br/>
              <div className="label-wrapper">
                <label className="label-english">メールアドレスを入力 *</label>
              </div>
              <div className="label-wrapper">
                <label className="label-english" style={{marginTop: "0"}}>Enter Email Address *</label>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value,
                showDecline: false,
                showNotFound: false,
                showRegistered: false,
                showRsvpPassed: false,
              })}
              className="form-control"
            />
            <br/>
            {this.renderPickADate()}
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-md-6" style={{textAlign: "left"}}>
            {
              showDecline ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                シスコの記録によると、以前に参加を辞退されたようです。ご質問がある場合は、<a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> までお問い合わせください。
                <br />
                <br />
                Our records indicate you previously declined to attend. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> if you have any questions.
              </div>
              :
              ""
            }
            {
              showRegistered ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                シスコの記録によると、すでにご登録されているようです。サポートが必要な場合は、<a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> までお問い合わせください。エグゼクティブ イノベーション フォーラム @ 2025 日本グランプリにご関心をお寄せいただきありがとうございます。
                <br />
                <br />
                Our records indicate you previously registered. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> for assistance. Thank you for your interest in attending the Executive Innovation Forum at the 2025 Japanese Grand Prix.
              </div>
              :
              ""
            }
            {
              showNotFound ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                エグゼクティブ イノベーション フォーラム @ 2025 日本グランプリにご関心をお寄せいただきありがとうございます。本イベントは招待制となりますので、ご招待時と同じメールアドレスをご使用くださいますようお願いいたします。サポートが必要な場合は、<a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> までお問い合わせください。
                <br />
                <br />
                Thank you for your interest in attending the Executive Innovation Forum at the 2025 Japanese Grand Prix. As this is an invitation only event, please ensure the email address used matches the invitation. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%E2%80%93%20Executive%20Innovation%20Forum%20%7C%202025%20Japanese%20Grand%20Prix">executiverelations@cisco.com</a> for assistance.
              </div>
              :
              ""
            }
            {
              showRsvpPassed ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                エグゼクティブ イノベーション フォーラム @ 2025 日本グランプリにご関心をお寄せいただきありがとうございます。シスコの記録によると、招待状は返信期限が過ぎているようです。
                <br />
                <br />
                Thank you for your interest in attending the Executive Innovation Forum at the 2025 Japanese Grand Prix. Our records indicate your invitation has expired.
              </div>
              :
              ""
            }
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit">
              <br/>
              <button
                type="button"
                onClick={this.verifyEmail}
                className={`${showDecline || showNotFound || showRegistered || showRsvpPassed ? "error-btn-grey" : ""} sg-button sg-button-secondary`}
                disabled={showDecline || showNotFound || showRegistered || showRsvpPassed}
              >
                続行 | Continue
              </button>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegistrationEmailValidation;
