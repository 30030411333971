import React from "react";
import { getItemBySlug } from "./CmsUtility";
class CmsPrint extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  formatHtml() {
    const { cms_section, slug, field } = this.props
    const cmsItem = getItemBySlug(cms_section, slug);
    const html = cmsItem.data[field];
    
    let formattedHtml = html.replaceAll("&amp;nbsp;", "&nbsp;")
    return formattedHtml
  }

  render() {
    const { cms_section, slug, field } = this.props
    // console.log(cms_section);
    if (cms_section === null || cms_section === undefined) return <></>;
    return <div dangerouslySetInnerHTML={{__html: this.formatHtml()}}/>;
  }
}

export default CmsPrint;
