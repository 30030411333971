import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true
        });
      }else if(json && json.error) {
        console.log('in here');
        this.setState({
          error: "The email address entered was not invited to this event."
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
              <h4>ご返信を確認いただきありがとうございます。</h4>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <div className="body1">
                ご質問がある場合は、シスコ エグゼクティブ イベント チームまでお問い合わせください。
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
              <h4>Thank you for confirming your response.</h4>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <div className="body1">
                If you have any questions, please contact the Cisco Executive Events Team.
                <br /><br />
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit closed-page-button" style={{marginTop: '30px', width: 'fit-content'}}>
              <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Executive%20Innovation%20Forum%20at%20the%202025%20Japanese%20Grand%20Prix?subject=Question%20%7C%20Executive%20Innovation%20Forum%20at%20the%202025%20Japanese%20Grand%20Prix?subject=Question%20%7C%20Executive%20Innovation%20Forum%20at%20the%202025%20Japanese%20Grand%20Prix">
                <div className="sg-button sg-button-secondary" type="button">
                お問い合わせ | Contact Us
                </div>
              </a>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
        <div className="col-xs-12">
          <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
            <h4>ご返信いただきありがとうございます。</h4>
          </div>
          <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
            <h6>エグゼクティブ イノベーション フォーラム @ 2025 日本グランプリにご参加いただけないことを残念に思います。今後開催されるシスコのイベントにご参加いただければ幸いです。ご返信を確認するために、以下にメールアドレスをご入力いただきますようお願いいたします。</h6>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
            <h4>Thank you for your response.</h4>
          </div>
          <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
            <h6>Sorry we will miss you at the Executive Innovation Forum at the 2025 Japanese Grand Prix. We look forward to hosting you at a future Cisco event. To confirm your response, please enter your email address below.</h6>
          </div>
          <div className="att-proam-registration-email-validation-fields">
            <br/>
            <div className="label-wrapper">
              <label className="label-english">メールアドレスを入力*<br />Enter Email Address *</label>
            </div>
          </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
            />
            <br />
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-md-6" style={{textAlign: "left"}}>
            {error ? (
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                {error}
              </div>
            ):""}
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit">
              <br/>
              <button
                type="button"
                onClick={this.declineEmail}
                className="sg-button sg-button-secondary"
              >
                送信 | Submit
              </button>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '36px'}}>
              <div className="body1">
                ご質問がある場合は、シスコ エグゼクティブ イベント <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Executive%20Innovation%20Forum%20at%20the%202025%20Japanese%20Grand%20Prix">チームまでお問い合わせください</a>。
                <br />
                If you have any questions, please <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Executive%20Innovation%20Forum%20at%20the%202025%20Japanese%20Grand%20Prix">contact</a> the Cisco Executive Events Team.
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          
        </form>
      </div>
    );
  }
}

export default RegistrationDecline;
